<template>
  <div class="cajas">
    <vx-card>
      <div class="flex justify-between items-center">
        <vs-input
          class="mb-4 w-full md:mb-0 mr-4"
          v-model="searchQuery"
          placeholder="Buscar..."
        />
        <div>
          <vs-button
            :to="{ name: 'finanzas-cajas-crear-caja' }"
            class="mb-4 md:mb-0"
            >Crear</vs-button
          >
        </div>
      </div>
      <vs-table ref="cajasTable" search class="mt-3" stripe :data="cajas">
        <template slot="thead">
          <vs-th> Caja </vs-th>
          <vs-th> Tipo </vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].caja">
              {{ data[indextr].name }}
            </vs-td>

            <vs-td
              :data="data[indextr].type"
              v-if="data[indextr].type === 'CASH'"
            >
              Efectivo
            </vs-td>
            <vs-td v-else>Banco</vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                @click="deleteAccount(tr, indextr)"
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cajas: [],
      searchQuery: '',
    };
  },
  watch: {
    searchQuery(value) {
      this.$refs.cajasTable.searchx = value;
    },
  },
  async mounted() {
    this.listAccounts();
  },
  methods: {
    async listAccounts() {
      try {
        const { data } = await this.$repositories.accounts.listAccounts();
        this.cajas = data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async deleteAccount(account, index) {
      try {
        await this.$repositories.accounts.deleteAccount(account.id);
        this.cajas.splice(index, 1);
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: error.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          position: 'top-right',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.cajas {
  .header-table.vs-table--header {
    display: none;
  }
}
</style>
